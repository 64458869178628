import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../services/analytics.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const analyticsService = this.analyticsService;
    const router = this.router;
    return next.handle(request).pipe(
      tap({
        error(err: any) {
          if (err instanceof HttpErrorResponse) {
            if (
              err.url.endsWith(environment.buildConfig) ||
              err.url === environment.addressLookupFind ||
              err.url === environment.addressLookupRetrieve
            ) {
              // Ignore these errors
              return;
            }
            // Report error in analytics
            analyticsService.triggerGenericEvent(
              'error',
              err.url.split('/').pop(),
              err.status.toString()
            );
            // Redirect back to client
            router.navigate(['redirect']);
          }
        }
      })
    );
  }
}
