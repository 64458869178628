import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false;
  title = 'argos-monthlies-spa';
  /**
   * Session length in minutes
   */
  private sessionLength = 30;
  /**
   * setTimeout ID
   */
  private sessionTimer: number;

  constructor(private router: Router) {
    this.initSessionTimeout();

    this.router.events.pipe(delay(0)).subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    const ro = new window.ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const height = entry.contentRect.height;
        if (height) {
          // NOTE: Target origin is wildcarded to allow for ALL Argos origins.
          // Messages do not contain any sensitive data and this implementation should be reviewed should there be a future request.
          window.parent.postMessage({ height }, '*');
        }
      }
    });
    ro.observe(document.body);
  }

  private initSessionTimeout(): void {
    this.sessionTimer = window.setTimeout(() => this.handleTimeout(), this.sessionLength * 60000);
  }

  private handleTimeout(): void {
    console.log('Session timeout');
    this.router.navigate(['redirect']);
  }
}
