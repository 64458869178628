import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/error/error.component';
// Guards
import { RedirectGuard } from './guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./containers/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'direct-debit',
    loadChildren: () =>
      import('./containers/direct-debit/direct-debit.module').then(
        (m) => m.DirectDebitModule
      )
  },
  {
    path: 'summary',
    loadChildren: () =>
      import('./containers/summary/summary.module').then((m) => m.SummaryModule)
  },
  { path: 'redirect', canActivate: [RedirectGuard], component: ErrorComponent },
  { path: '**', component: AppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
