import { Injectable } from '@angular/core';

export interface GenericGAEvent {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
}
export interface GtmData {
  [key: string]: any;
}

@Injectable()
export class AnalyticsService {
  constructor(private window: Window) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.window['dataLayer'] = this.window['dataLayer'] || [];
  }

  public push(data: GtmData) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.window['dataLayer'].push(data);
  }

  public triggerFormEvent(action: string, label: string) {
    const data: GenericGAEvent = {
      event: 'genericGAEvent',
      eventCategory: 'direct_debit',
      eventAction: action,
      eventLabel: label
    };
    this.push(data);
  }

  public triggerGenericEvent(category: string, action: string, label?: string) {
    const data: GenericGAEvent = {
      event: 'genericGAEvent',
      eventCategory: category,
      eventAction: action
    };
    if (label !== undefined) {
      data.eventLabel = label;
    }
    this.push(data);
  }

  public triggerPageview(pageId: string, additionalData: GtmData = {}) {
    this.push({
      pagename: `argos/monthlies/${pageId}`,
      genericpagename: 'argos/monthlies',
      category: 'argos',
      ...additionalData
    });
  }
}
