<div aria-busy="true" size="100" class="Spinnerstyles__Spinner">
  <svg viewBox="0 0 100 100" size="100" class="Spinnerstyles__SVG">
    <circle
      kind="default"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      stroke-width="3"
      stroke-miterlimit="10"
      class="Spinnerstyles__Circle"
    ></circle>
  </svg>
</div>
