import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { ApplicationService } from '../services/application.service';
import { environment } from 'src/environments/environment';

/**
 * Redirect to the client.
 */
@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private appService: ApplicationService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const appState = this.appService.getState();
    const handbackStatus = next.queryParamMap.get('success')
      ? 'SUCCESS'
      : 'FAILURE';
    const redirectUrl =
      (appState.returnUrl ? appState.returnUrl : this.getFallbackUrl()) +
      `?status=${handbackStatus}`;

    this.appService.resetState();
    // 17/06/2020 - reverted to redirect as the client is not ready to integrate.
    window.location.href = redirectUrl;

    // NOTE: Target origin is wildcarded to allow for ALL Argos origins.
    // Messages do not contain any sensitive data and this implementation should be reviewed should there be a future request.
    // window.parent.postMessage({ type: 'finished', handbackStatus }, '*');

    return false;
  }

  private getFallbackUrl(): string {
    const ref = document.referrer ? new URL(document.referrer) : '';
    const returnUrl =
      ref !== ''
        ? `${ref.origin}${environment.fallbackReturnUrl}`
        : environment.fallbackReturnUrl;

    return returnUrl;
  }
}
