import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {
  IValidateDirectDebitResponse,
  IOrderDetailsResponse,
  IApplicationState,
  IConfirmDirectDebitResponse,
  IConfig
} from './application.interface';

@Injectable()
export class ApplicationService {
  private apiBaseUrl: string;
  private initialState: IApplicationState = {};
  private state: BehaviorSubject<IApplicationState> = new BehaviorSubject(
    this.initialState
  );

  public readonly state$: Observable<
    IApplicationState
  > = this.state.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Authenticates a given id and jwt with the back-end.
   * @param id id
   * @param token jwt
   */
  public auth(id: string, token: string): Observable<any> {
    // This is the first call made by the application, whereby the base URL needs to be established.
    // Jenkins will generate /config.json including the base URL.
    return this.getConfig().pipe(
      // If there is an error retrieving the config (e.g. doesn't exist for local dev), use the environment default:
      catchError(() => of({ api: environment.apiBaseUrl })),

      switchMap((config: IConfig) => {
        // Set the base URL from config.json, or use the environment default if it's missing:
        if (config.api !== undefined) {
          this.apiBaseUrl = config.api;
        } else {
          this.apiBaseUrl = environment.apiBaseUrl;
        }

        // Return new observable for authentication call:
        return this.http.post<any>(
          `${this.apiBaseUrl}${environment.api.auth}`,
          { id },
          {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
            }),
            observe: 'response',
            withCredentials: environment.apiWithCredentials
          }
        );
      })
    );
  }

  /**
   * Confirms the Direct Debit payment information held in state.
   */
  public confirmDirectDebit(): Observable<IConfirmDirectDebitResponse> {
    const state = this.state.getValue();
    let payload = {
      paymentOrderId: state.auth.id,
      accName: state.directDebit.accountName,
      bankAccountNo: state.directDebit.accountNumber,
      bankSortCode: state.directDebit.accountSortcode.replace(/\s-\s/g, ''),
      nominatedPayDate: state.directDebit.directDebitDate,
      billingAddress: {
        line1: state.directDebit.billingAddress.addressLine1,
        line2: state.directDebit.billingAddress.addressLine2,
        line3: state.directDebit.billingAddress.town,
        line4: state.directDebit.billingAddress.county,
        postCode: state.directDebit.billingAddress.postcode,
        addressCountryCode: 'GBR',
        isCommercial: false
      },
      isApplianceAddressDiff: state.directDebit.applianceAddressCheckbox,
      requestDocViaPaper: state.directDebit.requestDocViaPaper
    };

    if (state.directDebit.applianceAddressCheckbox) {
      payload = Object.assign(payload, {
        applianceAddress: {
          line1: state.directDebit.applianceAddress.addressLine1,
          line2: state.directDebit.applianceAddress.addressLine2,
          line3: state.directDebit.applianceAddress.town,
          line4: state.directDebit.applianceAddress.county,
          postCode: state.directDebit.applianceAddress.postcode,
          addressCountryCode: 'GBR',
          isCommercial: false
        }
      });
    }

    // Loyalty
    if (state.directDebit.nectarCheckbox === 'Y') {
      payload = Object.assign(payload, {
        loyalty: {
          // eslint-disable-next-line id-blacklist
          number: state.directDebit.nectarCard,
          category: 'NECT' // Nectar
        }
      });
    }

    return this.http.post<IConfirmDirectDebitResponse>(
      `${this.apiBaseUrl}${environment.api.confirm}`,
      payload,
      {
        headers: new HttpHeaders({
          ...this.getAuthorizationHeader()
        }),
        withCredentials: environment.apiWithCredentials
      }
    );
  }

  /**
   * @returns the authorisation header for API requests.
   */
  private getAuthorizationHeader() {
    const state = this.state.getValue();
    return {
      Authorization: `Bearer ${state.auth.token}`,
    };
  }

  /**
   * Gets the Jenkins generated config.json
   */
  public getConfig() {
    return this.http.get<IConfig>(environment.buildConfig);
  }

  /**
   * Gets the details of the transaction from the client.
   */
  public getOrderDetails(): Observable<IOrderDetailsResponse> {
    const state = this.state.getValue();
    const api = environment.api.details.replace('{id}', state.auth.id);
    return this.http.get<IOrderDetailsResponse>(`${this.apiBaseUrl}${api}`, {
      headers: new HttpHeaders({
        ...this.getAuthorizationHeader()
      }),
      withCredentials: environment.apiWithCredentials
    });
  }

  /**
   * @returns The current value of the application state.
   */
  public getState(): IApplicationState {
    return this.state.getValue();
  }

  /**
   * Reset the application state.
   */
  public resetState() {
    this.state.next(this.initialState);
  }

  /**
   * Merges the given data with the application state.
   * @param data Object - data to add to the state.
   */
  public setState(data: IApplicationState) {
    const currentState = this.state.getValue();
    const nextState = Object.assign({}, currentState, data);
    this.state.next(nextState);
  }

  /**
   * Validates Direct Debit details.
   * @param bankAccountNo Bank account number.
   * @param bankSortCode Sortcode.
   */
  public validateDirectDebit(
    bankAccountNo,
    bankSortCode
  ): Observable<IValidateDirectDebitResponse> {
    const state = this.state.getValue();
    return this.http.post<IValidateDirectDebitResponse>(
      `${this.apiBaseUrl}${environment.api.validateDD}`,
      {
        id: state.auth.id,
        bankAccountNo,
        bankSortCode
      },
      {
        headers: new HttpHeaders({
          ...this.getAuthorizationHeader()
        }),
        withCredentials: environment.apiWithCredentials
      }
    );
  }
}
